import React from "react";

import Layout from "../components/Layout/Layout";
import Policy from "../components/ChildrensPrivacyPolicy/Policy";
import Hero from "../components/ChildrensPrivacyPolicy/Hero";

import "../styles/pages/privacyPolicy.scss";

export default function ChildrensPrivacyPolicy(props) {
  return (
    <Layout>
      <Hero />
      <Policy />
    </Layout>
  );
}
