import React from "react";
import { Link } from 'gatsby';

export default function Policy() {
  return (
    <div className="privacy-terms">
      <p className="effective-date">Effective Date: December 12, 2024</p>
      <p>
        For learners under 13 years of age, Learner accounts must be associated
        with a Parent account. DeltaMath for Home prohibits children under the
        age of 13 from creating independent Learner accounts and does not
        knowingly collect their personal information without consent of a parent
        or guardian. Parents can create Learner accounts linked to their Parent
        accounts, allowing supervised access to the service. By setting up a
        Learner account, parents or guardians consent to the practices outlined
        in the policy regarding their child's personal information. DeltaMath
        for Home collects only necessary information and ensures parents or
        guardians have control over their child's use. For learners 13 years of
        age or older, Learner accounts may be created independently. This
        Children's Privacy Policy applies to all Learner accounts.{" "}
      </p>

      <h2>1. Creating a Learner Account</h2>
      <p>
        A DeltaMath for Home Learner account cannot be created directly by
        anyone under 13 years of age. Learners under 13 years of age must be
        invited to create an account by a parent or guardian using a DeltaMath
        for Home Parent account. The parent or guardian actively agrees to the{" "}
        <Link to="/terms-of-service">Terms of Service</Link>,{" "}<Link to="/privacy-policy">Privacy Policy</Link>, and this Children's Privacy Policy.{" "}
      </p>

      <p>
        Learners 13 years of age or older can also be invited to create an
        account by a parent or guardian using a DeltaMath for Home Parent
        account. However, they may also create an independent Learner account.
        In this case, the learner actively agrees to the{" "}<Link to="/terms-of-service">Terms of Service</Link>,{" "}
        <Link to="/privacy-policy">Privacy Policy</Link>, and this Children's Privacy Policy.{" "}
      </p>

      <h2>2. How DeltaMath for Home Collects and Uses Information</h2>
      <p>
        During the setup of a Parent account on DeltaMath for Home, parents or
        guardians provide information about their authorized learner(s),
        including a profile name. DeltaMath for Home does not collect birthdates
        or sensitive personal information from child users. Children can change
        their profile details, which parents can view.
      </p>

      <p>
        DeltaMath for Home collects information about the learner's use of the
        Service, including answers to questions, to provide feedback and compile
        reports. Additionally, it automatically gathers usage data, such as IP
        addresses, device details, and browsing behavior, through tracking
        technologies. This data helps DeltaMath for Home improve the Service,
        personalize content, enhance security, and provide technical support.
        For more details on data collection practices, refer to our Privacy
        Policy.
      </p>

      <h2>3. How DeltaMath for Home Shares Your Information</h2>
      <p>
        Only in the following instances would DeltaMath for Home share a child's
        personal information: in order to provide Services, as required by law,
        or at the request of a parent or guardian. This includes sharing data
        with trusted vendors and service providers for analytics and hosting.
        Disclosure may occur to comply with legal requirements, enforce terms of
        service, or address security issues. In the event of a merger or sale,
        personal information may also be transferred. Additionally, DeltaMath
        for Home may share aggregated or de-identified information that cannot
        identify individual users.
      </p>

      <h2>4. DeltaMath for Home in Schools</h2>
      <p>
        If DeltaMath for Home is used in schools for educational purposes, the
        school may allow DeltaMath for Home to collect information from children
        under 13. Parents or guardians can contact the school to request access
        to, review, or delete their child's information, and DeltaMath will
        process these requests as instructed by the school.{" "}
      </p>

      <p>
        For more details on how DeltaMath protects student data, including for
        those under 13, please read our <Link to="/privacy-policy">Privacy Policy</Link>.
      </p>

      <h2>5. How to Access and Delete your Child's Information</h2>
      <p>
        Parents and guardians can review the information collected from their
        child's profile by signing into their Parent account. They can stop
        further data collection by discontinuing the child's access to the
        service. DeltaMath for Home will keep personal information as long as
        needed to provide the Service and will de-identify or delete it after
        receiving a deletion request, or 2 years after the Parent's subscription
        ends. If an independent Learner does not have a Parent subscription
        associated with their account, then the information will be deleted 2
        years after the independent Learner's subscription ends. To request
        deletion of personal information, parents or guardians can contact
        DeltaMath at home@deltamath.com.{" "}
      </p>

      <h2>6. Contact Us</h2>
      <p>
        If you have any questions about this policy, contact us directly at
        home@deltamath.com.
      </p>
    </div>
  );
}
